import { useEffect, useState } from 'react';
import { IConfigData } from '../../../../interfaces';

export const useFilteredConfigs = (
  configs: any,
  status: string,
  sort: string,
) => {
  const [filteredConfigs, setFilteredConfigs] = useState<IConfigData[]>([]);

  useEffect(() => {
    let localConfigs: IConfigData[] = [];

    if (status === 'All status') localConfigs = [...configs];
    else {
      localConfigs = configs?.filter(
        (config: IConfigData) => config.status === status,
      );
    }
    if (sort === 'descending') {
      localConfigs = localConfigs?.sort(
        (a: any, b: any) =>
          (new Date(b.creationTime) as any) - (new Date(a.creationTime) as any),
      );
    }
    if (sort === 'ascending') {
      localConfigs = localConfigs?.sort(
        (a: any, b: any) =>
          (new Date(a.creationTime) as any) - (new Date(b.creationTime) as any),
      );
    }
    setFilteredConfigs(localConfigs);
  }, [configs, status, sort]);

  return { filteredConfigs };
};
