import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useStyles } from '../../../styles';
import BackButton from '../../../../utils/backButton';
import { HelpComponent } from '../../../../utils/helpComponent';
import HELP_URL from '../../../../utils/helpLinkConstant';
import EmptyConfigBox from './components/EmptyConfigBox';
import Search from '@material-ui/icons/Search';
import RunsTable, { IRunsTableProps } from './components/RunsTable';
import { usePTRuns } from './hooks/usePTRuns';
import { useFilteredRuns } from './hooks/useFilteredRuns';
import { ITPContext, TPContext } from '../../../providers/TPProvider';
import Add from '@material-ui/icons/Add';
import Refresh from '@material-ui/icons/Refresh';
import ConfigPopup from './components/ConfigPopup';

const RunsPage = ({ isToolAdmin }: { isToolAdmin: boolean }) => {
  const classes = useStyles();

  const { projectId } = useParams();
  const navigate = useNavigate();

  const [filterOption, setFilterOption] = useState({
    status: 'All status',
    config: 'All config',
  });
  const [statusType, setStatusType] = useState<any>([]);
  const [config, setConfig] = useState<any>([]);

  const emptyPopupState = {
    open: false,
    onClose: () => {},
    popupContent: {
      message: '',
      title: '',
      confirmation: false,
    },
  };

  const [viewInfoPopup, setViewInfoPopup] = useState({
    ...emptyPopupState,
  });

  const handleCloseInfoPopup = () => {
    setViewInfoPopup(prevState => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const handleViewInfoClicked = (message: string) => {
    setViewInfoPopup({
      open: true,
      onClose: () => {
        handleCloseInfoPopup();
      },
      popupContent: {
        message,
        title: 'Info',
        confirmation: false,
      },
    });
  };

  const navigateToAddConfig = () => {
    navigate('../../manage/add-config');
  };
  const { runs, stopRun, downloadRunResults, loading, refreshRuns } = usePTRuns(
    projectId!,
    true,
  );
  const [searchText, setSearchText] = useState<string>('');
  const [sort, setSort] = useState<IRunsTableProps['sort']>('descending');

  useEffect(() => {
    if (runs && runs.length > 0) {
      setStatusType([
        'All status',
        ...new Set(runs.map((run: any) => run.status)),
      ]);
      setConfig(['All config', ...new Set(runs.map((run: any) => run.region))]);
    }
  }, [runs]);

  const { filteredRuns } = useFilteredRuns(
    runs,
    filterOption,
    searchText,
    sort,
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  // For Flow availble related check
  const { apmInfo }: ITPContext = useContext(TPContext);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.flexSpacebetween}
        >
          <BackButton />
          <HelpComponent helpUrl={HELP_URL} />
        </Grid>
      </Grid>
      <Grid container className={classes.runsPageContainer} spacing={2}>
        <Grid item xs={12} sm={12} md={1} lg={1}>
          <Typography variant="h5">Runs</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={11}
          lg={11}
          className={classes.runsPageActionButtons}
        >
          <Button
            variant="contained"
            size="medium"
            onClick={() => refreshRuns()}
            disabled={!apmInfo?.flows || filteredRuns?.length === 0}
            className={`${classes.refreshButton} ${classes.padding1} ${classes.minWidthAuto}`}
          >
            <Refresh />
          </Button>
          <FormControl>
            <InputLabel shrink variant="outlined">
              Config
            </InputLabel>
            <Select
              className={classes.statusDropdown}
              label="Config"
              id="feedback-register-types"
              multiline
              onChange={(val: any) => {
                setFilterOption({
                  ...filterOption,
                  config: val.target.value,
                });
              }}
              disabled={!apmInfo?.flows || config.length === 0}
              value={filterOption.config}
              variant="outlined"
            >
              {config &&
                config.map((t: string) => (
                  <MenuItem key={`type-option-${t}`} value={t}>
                    {t}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel shrink variant="outlined">
              Status
            </InputLabel>
            <Select
              className={classes.statusDropdown}
              label="Status"
              id="feedback-register-types"
              multiline
              onChange={(val: any) => {
                setFilterOption({
                  ...filterOption,
                  status: val.target.value,
                });
              }}
              disabled={!apmInfo?.flows || statusType.length === 0}
              value={filterOption.status}
              variant="outlined"
            >
              {statusType &&
                statusType.map((t: string) => (
                  <MenuItem key={`type-option-${t}`} value={t}>
                    {t}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            type="search"
            className={classes.searchInRunsPage}
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search runs"
            InputProps={{ endAdornment: !searchText && <Search /> }}
          />
          {isToolAdmin ? (
            <Button
              variant="contained"
              color="primary"
              onClick={navigateToAddConfig}
              className={classes.sentenceCaseText}
              disabled={!apmInfo?.flows}
            >
              <Add />
              Add Config
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <Grid container className={classes.emptyBoxContainer} spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {loading && <LinearProgress />}
          {filteredRuns?.length === 0 ? (
            !loading && (
              <EmptyConfigBox
                navigatePath="../../manage/add-config"
                flows={apmInfo?.flows}
                isToolAdmin={isToolAdmin}
                text="runs"
              />
            )
          ) : (
            <RunsTable
              onDownloadRunResults={(immutableId: string) =>
                downloadRunResults(immutableId)
              }
              onViewInfoClicked={handleViewInfoClicked}
              onStopRun={(immutableId: string) => {
                stopRun(immutableId).then(() => refreshRuns());
              }}
              runsData={filteredRuns}
              sort={sort}
              onSort={setSort}
            />
          )}
        </Grid>
      </Grid>
      {viewInfoPopup?.open && <ConfigPopup {...viewInfoPopup} />}
    </>
  );
};

export default RunsPage;
