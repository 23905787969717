import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import LeftArrowIcon from '../icons/leftArrowIcon';
import RightArrowIcon from '../icons/rightArrowIcon';
import { PromotionApi, promotionApiRef } from '../../../../apis/promotionApi';
import ShortImagePromotion from './shortImagePromotion';
import PosterImagePromotion from './posterImagePromotion';
import EmblaCarousel from '../../Carousal/EmblaCarousel';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      color: theme.palette.customCard.cardHeader + '!important',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '16px',
    },
    moreBtn: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    promotionHeight: {
      height: '140px',
    },
    promotions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    btnIcons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '2px',
    },
    arrowIcons: {
      marginRight: '5px',
    },
    arrowColorIcons: {
      cursor: 'pointer',
      color: '#9aa9b3',
    },
    newPromotionHeight: {
      height: '100%',
    },
    newPromotion: {
      display: 'flex',
      height: '100%',
      position: 'relative',
    },
  }),
);

const PromotionsWidget = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[] | undefined>([]);

  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const promotionApi: PromotionApi = useApi(promotionApiRef);
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      containScroll: 'trimSnaps',
      slidesToScroll: 1,
      align: 'start',
      loop: true,
    },
    [
      Autoplay({
        stopOnInteraction: false,
        // active: false,
        stopOnMouseEnter: true,
      }),
    ],
  );

  const navigate = useNavigate();

  const onClickSeeMore = () => {
    navigate('/promotions');
  };

  const getPromotionsData = () => {
    setLoading(true);
    promotionApi
      .getPromotionData()
      .then((data: any) => {
        setLoading(false);
        if (Array.isArray(data)) {
          setData(data?.filter((item: any) => item.isActive === true));
        } else {
          setData([]);
        }
      })
      .catch((error: any) => {
        setLoading(false);

        notificationApi.sendNotification({
          message: `Error occurred - ${error?.message}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    getPromotionsData();
  }, []);

  const onClickLeftArrow = () => {
    if (emblaApi && emblaApi?.canScrollPrev()) {
      emblaApi?.scrollPrev();
    }
  };

  const onClickRightArrow = () => {
    if (emblaApi && emblaApi?.canScrollNext()) {
      emblaApi?.scrollNext();
    }
  };

  return (
    <>
      {data && Array.isArray(data) && data?.length > 0 && (
        <Box
          sx={{
            mt: 2,
            // @ts-expect-error
            scrollBehavior: 'smooth',
          }}
          className={classes.promotionHeight}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ height: '45px' }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography className={classes.themeColor}>
                      Promotions
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className={classes.promotions}>
                      {!isMobile && (
                        <Box className={classes.btnIcons}>
                          <div
                            className={classes.arrowIcons}
                            onClick={onClickLeftArrow}
                          >
                            <LeftArrowIcon
                              className={classes.arrowColorIcons}
                            />
                          </div>
                          <div
                            className={classes.arrowIcons}
                            onClick={onClickRightArrow}
                          >
                            <RightArrowIcon
                              className={classes.arrowColorIcons}
                            />
                          </div>
                        </Box>
                      )}
                      <Typography
                        className={classes.moreBtn}
                        onClick={onClickSeeMore}
                      >
                        See more
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.newPromotionHeight}>
                    {loading && <Typography>Loading ...</Typography>}
                    {(loading === false && data === undefined) ||
                      (!loading && data?.length === 0 && (
                        <Typography>No Promotions available</Typography>
                      ))}
                    <Box className={`promotions-new ${classes.newPromotion}`}>
                      {loading === false && (
                        <EmblaCarousel
                          ref={emblaRef}
                          slides={data.map(item => (
                            <div key={item?.promotionId}>
                              {item?.templateType === 'Poster Image' && (
                                <PosterImagePromotion promotion={item} />
                              )}
                              {item?.templateType === 'Short Image' && (
                                <ShortImagePromotion promotion={item} />
                              )}
                            </div>
                          ))}
                        ></EmblaCarousel>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default PromotionsWidget;
