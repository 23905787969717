import {
  Grid,
  LinearProgress,
  TextField,
  useMediaQuery,
  useTheme,
  Theme,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import BackButton from '../../utils/backButton';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import { useStyles } from '../styles';
import AddTool, { AddToolProps } from './popups/AddTool';
import SearchIcon from '@material-ui/icons/Search';
import { useGetTools } from '../hooks/useGetTools';
import { useNavigate, useParams } from 'react-router';
import ToolCard from './cards/ToolCard';
import { IAvailableTool, IProvisionedTool } from '../interfaces';
import ToolDetails from './popups/ToolDetails';
import Refresh from '@material-ui/icons/Refresh';

const ToolsManagementPage = ({
  type,
  isToolAdmin,
}: {
  type: 'service' | 'tool';
  isToolAdmin: boolean;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [refreshAddTool, setRefreshAddTool] = useState(0);
  const [searchText, setSearchText] = useState('');
  const splitTreatments = useSplitTreatments({
    names: ['devx-ui-feature-enable']
  });
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  const { projectId } = useParams();
  const [refreshCounterTools, setRefreshCounterTools] = useState(0);

  const [availableTools, provisionedTools, isToolsLoading] = useGetTools(
    projectId!,
    refreshCounterTools,
    1000,
  );

  const [addToolLoader, setAddToolLoader] = useState(false);

  const handleAddToolClicked = () => {
    handleClose();
    setAddToolLoader(true);
  };
  const handleAddToolSuccess = () => {
    setOpen(false);
    setAddToolLoader(false);
    setRefreshCounterTools(prevState => prevState + 1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredTools = availableTools
    .filter(tool => tool.metadata.type === type)
    .filter(
      tool =>
        tool.metadata.tool.toLowerCase().includes(searchText.toLowerCase()) ||
        tool.metadata.name.toLowerCase().includes(searchText.toLowerCase()) ||
        tool.metadata.description
          .toLowerCase()
          .includes(searchText.toLowerCase()),
    );

  const [selectedTool, setSelectedTool] = useState<IAvailableTool>();
  const [actionType, setActionType] =
    useState<AddToolProps['actionType']>('provision');

  const handleAddToolsOpen = (
    toolData: IAvailableTool,
    __actionType: AddToolProps['actionType'],
  ) => {
    setSelectedTool(toolData);
    setActionType(__actionType);
    setOpen(true);
    setRefreshAddTool(prevState => prevState + 1);
  };
  const navigate = useNavigate();

  const [openDetails, setOpenDetails] = useState(false);
  const handleToolDetails = (
    tool: IAvailableTool,
    __provisionedTools: IProvisionedTool[],
  ) => {
    switch (tool.tool) {
      case 'enable-performanceTest': {
        const performanceTool = provisionedTools
          ?.filter(
            (provisionedTool: IProvisionedTool) =>
              provisionedTool.statusmsg === 'Enabled',
          )
          .find((__tool: IProvisionedTool) => tool.tool === __tool.tool);
        if (performanceTool) {
          navigate('./performance-test/runs');
        } else {
          setSelectedTool(tool);
          setOpenDetails(true);
        }
        break;
      }
      default: {
        setSelectedTool(tool);
        setOpenDetails(true);
        break;
      }
    }
  };

  const handleDetailsClose = () => {
    setOpenDetails(false);
  };

  const loaderRunning = isToolsLoading || addToolLoader || !provisionedTools;
  console.log(splitTreatments?.treatments['devx-ui-feature-enable'].config)
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.flexSpacebetween}
        >
          <BackButton />
          <div
            className={`${classes.flex} ${classes.gap1} ${classes.flexEnd} ${classes.width50Percent}`}
          >
            <Button
              variant="text"
              color="primary"
              size="medium"
              onClick={() => setRefreshCounterTools(prevState => prevState + 1)}
              className={`${classes.padding1} ${classes.minWidthAuto}`}
            >
              <Refresh />
            </Button>
            {/* Search will come here */}

            {!isMobile ? (
              <TextField
                className={`${classes.backGroundColor} ${classes.desktopSearch}`}
                variant="outlined"
                type="search"
                placeholder="Search services"
                onChange={handleSearchChange}
                value={searchText}
                InputProps={{ endAdornment: !searchText && <SearchIcon /> }}
              />
            ) : null}

            <HelpComponent helpUrl={HELP_URL.PROVISIONING_CENTER_SERVICES} />
          </div>
        </Grid>

        {isMobile ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              className={classes.backGroundColor}
              variant="outlined"
              type="search"
              placeholder="Search services"
              onChange={handleSearchChange}
              value={searchText}
              fullWidth
              InputProps={{ endAdornment: !searchText && <SearchIcon /> }}
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid container className={classes.pagePadding}>
        {loaderRunning ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LinearProgress />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container xs={12} className={classes.alert}>
              <Alert severity='info'>
                To enable, disable, or modify any services for this application, you must be the Primary Support Owner or an Admin. These roles are listed on the 'APM INFO' and 'ADMINS' tabs, respectively. You will need to request one of these users to either make changes on your behalf or grant you Admin access to perform the changes yourself.
              </Alert>
            </Grid>
            <Grid container>
              {
                // splitTreatments.factory && 
                // splitTreatments?.treatments['devx-ui-feature-enable'].config &&
                filteredTools?.map((tool: any) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={tool.tool}>
                    <ToolCard
                      buttonVisible={isToolAdmin}
                      tool={tool}
                      provisionedTools={provisionedTools}
                      onAddToolClicked={() =>
                        handleAddToolsOpen(tool, 'provision')
                      }
                      onRemoveToolClicked={() =>
                        handleAddToolsOpen(tool, 'deprovision')
                      }
                      onHeadingClicked={() =>
                        handleToolDetails(tool, provisionedTools)
                      }
                    />
                  </Grid>
                ))
              }

              {filteredTools?.length === 0 ? (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  {type === 'service' ? 'No Services found' : 'No Tools found'}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        )}
      </Grid>

      {selectedTool ? (
        <AddTool
          key={refreshAddTool}
          open={open}
          onClose={handleClose}
          onAddToolClicked={handleAddToolClicked}
          onAddToolSuccess={handleAddToolSuccess}
          toolToAdd={selectedTool}
          actionType={actionType}
        />
      ) : null}

      {selectedTool ? (
        <ToolDetails
          key={refreshAddTool}
          open={openDetails}
          onClose={handleDetailsClose}
          onOkClicked={handleDetailsClose}
          tool={selectedTool}
        />
      ) : null}
    </>
  );
};

export default ToolsManagementPage;
