import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  LinearProgress,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { SonarQubeCard } from './sonarQube';
import { SnykCard } from './snyk';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { Autocomplete } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useGetBranches } from './hooks/useGetBranches';
import { AuthContext } from '../../../../providers/AuthProvider';
import { additionalConfigApiRef } from '../../../../apis/additionalConfigApi';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

const CodeQualityPage = () => {
  const [branch, setBranch] = useState<string>('');
  const [snykOrgId, setSnykOrgId] = useState<any>('');

  const [selectedBranch, setSelectedBranch] = useState<string>('');

  const additionalConfigApi = useApi(additionalConfigApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    if (additionalConfigApi) {
      additionalConfigApi
        .getFrontEndConfig()
        .then((config: any) => {
          setSnykOrgId(config?.frontendConfig?.data?.snykOrgID);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, []);

  const { entity } = useAsyncEntity();

  const { profEmail } = useContext(AuthContext);
  const useStyles = makeStyles(theme => ({
    branchContainer: {
      display: 'flex',
    },
    branch: {
      width: 300,
      marginRight: '16px',
    },
  }));

  const classes = useStyles();

  const userId = profEmail.split('@')[0].toLowerCase();
  const isOwner =
    userId === `${entity?.spec?.owner}`?.toLowerCase()?.replace('user:', '');

  const { loading: branchesLoading, branchList } = useGetBranches(
    entity,
    isOwner,
  );

  const onClickUpdateBranch = () => {
    setSelectedBranch(branch || '');
  };

  const onChangeUpdateBranch = (value: string) => {
    setBranch(value || '');
  };

  return (
    <Grid container spacing={2}>
      {branchesLoading && <LinearProgress />}
      {isOwner && (
        <Grid item xs={12}>
          <div className={classes.branchContainer}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              value={branch}
              options={branchList}
              onChange={(event, newValue) =>
                onChangeUpdateBranch(newValue || '')
              }
              className={classes.branch}
              renderInput={params => (
                <TextField {...params} label="Branch" variant="outlined" />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClickUpdateBranch()}
            >
              <RefreshIcon />
            </Button>
          </div>
        </Grid>
      )}

      <Grid item xs={12} md={6} lg={6}>
        <SonarQubeCard variant="gridItem" selectedBranch={selectedBranch} />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <SnykCard
          snykOrgID={snykOrgId}
          selectedBranch={selectedBranch || 'default_branch'}
        />
      </Grid>
    </Grid>
  );
};
export default CodeQualityPage;
