/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router';
import { useStyles } from '../../../styles';
import BackButton from '../../../../utils/backButton';
import { HelpComponent } from '../../../../utils/helpComponent';
import HELP_URL from '../../../../utils/helpLinkConstant';
import ManageConfigTable from './components/ManageConfigTable';
import Refresh from '@material-ui/icons/Refresh';
import Add from '@material-ui/icons/Add';
import { usePTOperations } from './hooks/usePTOperations';
import EmptyConfigBox from './components/EmptyConfigBox';
import { ITPContext, TPContext } from '../../../providers/TPProvider';
import { useFilteredConfigs } from './hooks/useFilteredConfigs';

const ManageConfigPage = ({ isToolAdmin }: { isToolAdmin: boolean }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [filterOption, setFilterOption] = useState({
    status: 'All status',
  });
  const [statusType, setStatusType] = useState<any>([]);

  const { projectId } = useParams();
  const {
    configs,
    loading,
    runConfig,
    deleteConfig,
    duplicateConfig,
    refreshTime,
    refreshConfigs,
  } = usePTOperations(projectId!, true);

  const [sort, setSort] = useState('descending');

  const { filteredConfigs } = useFilteredConfigs(
    configs,
    filterOption.status,
    sort,
  );

  useEffect(() => {
    if (configs && configs.length > 0) {
      setStatusType([
        'All status',
        ...new Set(configs.map((config: any) => config.status)),
      ]);
    }
  }, [configs]);

  const navigateToAddConfig = () => {
    navigate('./add-config');
  };

  // For Flow availble related check
  const { apmInfo }: ITPContext = useContext(TPContext);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.flexSpacebetween}
        >
          <BackButton />
          <HelpComponent helpUrl={HELP_URL} />
        </Grid>
      </Grid>

      <Grid container className={classes.runsPageContainer}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant="h5">Manage Config</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className={`${classes.manageConfigHeaderButtons} ${classes.flexCenter}`}
        >
          <Button
            variant="contained"
            size="medium"
            disabled={!apmInfo?.flows || configs?.length === 0}
            className={`${classes.refreshButton} ${classes.padding1} ${classes.minWidthAuto}`}
            onClick={() => refreshConfigs()}
          >
            <Refresh />
          </Button>
          <div className={`${classes.configDropdown}`}>
            <FormControl>
              <InputLabel shrink variant="outlined">
                Status
              </InputLabel>
              <Select
                className={classes.statusDropdown}
                label="Status"
                id="feedback-register-types"
                multiline
                onChange={(val: any) => {
                  setFilterOption({
                    ...filterOption,
                    status: val.target.value,
                  });
                }}
                disabled={!apmInfo?.flows || statusType.length === 0}
                value={filterOption.status}
                variant="outlined"
              >
                {statusType &&
                  statusType.map((t: string) => (
                    <MenuItem key={`type-option-${t}`} value={t}>
                      {t}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {isToolAdmin ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.manageConfigHeaderActionButton}
              onClick={navigateToAddConfig}
              disabled={!apmInfo?.flows}
            >
              <Add />
              Add Config
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <Grid container className={classes.emptyBoxContainer} spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {loading && <LinearProgress />}
          {configs?.length === 0 ? (
            !loading && (
              <EmptyConfigBox
                navigatePath="./add-config"
                flows={apmInfo?.flows}
                isToolAdmin={isToolAdmin}
                text="configs"
              />
            )
          ) : (
            <ManageConfigTable
              isToolAdmin={isToolAdmin}
              refreshTime={refreshTime}
              onRun={(configId: string) =>
                runConfig(configId).then(() => refreshConfigs())
              }
              onEdit={(id: string) => {
                navigate(`./edit-config/${id}`, {
                  state: {
                    ...configs.find(
                      (config: any) => config.devxConfigId === id,
                    ),
                  },
                });
              }}
              onDuplicate={(config: any) => {
                duplicateConfig(config).then(() => refreshConfigs());
              }}
              onDelete={(configId: string) => {
                deleteConfig(configId);
              }}
              configData={filteredConfigs}
              sort={sort}
              onSort={setSort}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ManageConfigPage;
