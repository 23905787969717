import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useStyles } from '../../../../styles';
import {
  IApmDetails,
  IFailureCriteria,
  IOptionsFailureCriteria,
} from '../../../../interfaces';
import { v4 as uuidv4 } from 'uuid';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';

const FailureCriteria = ({
  isVisible,
  apmInfo,
  onPrevious,
  onNext,
  options,
  initialState,
}: {
  isVisible: boolean;
  apmInfo: IApmDetails | null;
  onPrevious: Function;
  onNext: Function;
  options: IOptionsFailureCriteria[];
  initialState: IFailureCriteria[] | undefined;
}) => {
  const classes = useStyles();

  const [metricsObject, setMetricsObject] = useState<any>(null);
  useEffect(() => {
    const __metricsObject = options?.reduce(
      (accumulator: any, optionObj: any) => {
        return {
          ...accumulator,
          [optionObj.metric]: {
            ...optionObj,
          },
        };
      },
      {},
    );
    setMetricsObject(__metricsObject);
  }, [options]);

  const emptyFailureCriteria = {
    main: {
      metric: '',
      aggregateFunction: '',
      condition: '',
      threshold: '',
      requestName: '',
    },
    errors: {
      metric: '',
      aggregateFunction: '',
      condition: '',
      threshold: '',
      requestName: '',
    },
  };

  const [failureCriterias, setFailureCriterias] = useState<any>([]);

  const getAddedMetricCount = (metric: string) => {
    return failureCriterias.filter(
      (failureCriteria: any) => failureCriteria.main.metric === metric,
    ).length;
  };

  const maxUseageValidate = (metric: string) => {
    return metricsObject[metric].maximumUsage >= getAddedMetricCount(metric);
  };

  const handleAddFailureCriteria = (failureCriteriaObj?: IFailureCriteria) => {
    setFailureCriterias((prevState: any) => [
      ...prevState,
      {
        id: uuidv4(),
        main: failureCriteriaObj
          ? { ...failureCriteriaObj }
          : { ...emptyFailureCriteria.main },
        errors: { ...emptyFailureCriteria.errors },
      },
    ]);
  };

  const handleDeleteDependency = (id: string) => {
    setFailureCriterias(
      failureCriterias.filter(
        (failureCriteria: any) => failureCriteria?.id !== id,
      ),
    );
  };

  const handleCriteriaChange = (event: any, id: string) => {
    const { name, value } = event.target;
    setFailureCriterias((prevState: any) =>
      prevState.map((criteria: any) => {
        return criteria.id === id
          ? {
              ...criteria,
              main:
                name === 'metric'
                  ? { ...emptyFailureCriteria.main, [name]: value }
                  : { ...criteria.main, [name]: value },
              errors: { ...criteria.errors, [name]: '' },
            }
          : {
              ...criteria,
              errors: {
                ...criteria.errors,
                [name]: name === 'metric' ? '' : criteria.errors[name],
              },
            };
      }),
    );
  };

  const updateErrors = (errors: any, main: any) => {
    const errorObj: any = {};
    if (main.metric === 'error') {
      delete errors.requestName;
    }
    Object.keys(errors).forEach(key => {
      const ObjKey: string = key as keyof Object;
      errorObj[ObjKey] = main[ObjKey] ? '' : `${key} is required`;

      if (ObjKey === 'metric' && main.metric) {
        errorObj[ObjKey] = maxUseageValidate(main.metric)
          ? ''
          : `max usage for ${main.metric} ${ObjKey} is ${
              metricsObject[main.metric].maximumUsage
            }`;
      }
    });

    return {
      errorObj,
      hasError: Object.values(errorObj).some((x: any) => x !== ''),
    };
  };

  const validate = () => {
    let errorsArr: boolean[] = [];
    const arr = failureCriterias.map((failureCriteria: any) => {
      const { main, errors } = failureCriteria;
      const { errorObj, hasError } = updateErrors(errors, main);
      errorsArr = [...errorsArr, hasError];
      return { ...failureCriteria, errors: errorObj };
    });
    setFailureCriterias(arr);
    return errorsArr.every((x: any) => x === false);
  };

  const handleSave = () => {
    if (validate()) {
      const __failureCriteria = failureCriterias?.map(
        (failureCriteria: any) => failureCriteria.main,
      );
      onNext({ failureCriteria: __failureCriteria });
    }
  };

  useEffect(() => {
    if (initialState && initialState?.length > 0 && metricsObject) {
      setFailureCriterias([]);
      initialState?.map((failureCriteria: IFailureCriteria) => {
        handleAddFailureCriteria(failureCriteria);
      });
    }
  }, [metricsObject, initialState]);

  return (
    <Card
      className={`${classes.heigt100Percent} ${
        isVisible ? '' : classes.displayNone
      } `}
    >
      <CardHeader
        title="Failure Criteria"
        action={
          <IconButton
            className={classes.padding1}
            onClick={() => handleAddFailureCriteria()}
          >
            <AddIcon fontSize="medium" />
          </IconButton>
        }
      />
      <CardContent className={`${classes.overflowAuto}`}>
        {failureCriterias.length === 0 ? (
          <Typography component="div" className={classes.emptyPageText}>
            Failure Criteria is optional. To add new criteria, click (+) button
          </Typography>
        ) : (
          failureCriterias?.map((failureCriteria: any) => {
            return (
              <Grid
                container
                spacing={4}
                key={failureCriteria.id}
                className={classes.flexNoWrap}
              >
                <Grid
                  item
                  xs="auto"
                  sm="auto"
                  md={11}
                  lg={11}
                  //   className={classes.flexGrow1}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        {...(failureCriteria.errors.metric && {
                          error: true,
                          helperText: failureCriteria.errors.metric,
                        })}
                      >
                        <InputLabel htmlFor="outlined-native-simple">
                          Metric
                        </InputLabel>
                        <Select
                          value={failureCriteria.main.metric}
                          onChange={(event: any) => {
                            handleCriteriaChange(event, failureCriteria.id);
                          }}
                          label="Metric"
                          name="metric"
                        >
                          {metricsObject &&
                            Object.keys(metricsObject)?.map(
                              (metric: string) => (
                                <MenuItem value={metric} key={metric}>
                                  {metric}
                                </MenuItem>
                              ),
                            )}
                        </Select>
                        <FormHelperText>
                          {failureCriteria.errors.metric}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        disabled={!failureCriteria.main.metric}
                        {...(failureCriteria.errors.aggregateFunction && {
                          error: true,
                          helperText: failureCriteria.errors.aggregateFunction,
                        })}
                      >
                        <InputLabel htmlFor="outlined-native-simple">
                          Aggregate function
                        </InputLabel>
                        <Select
                          value={failureCriteria.main.aggregateFunction}
                          onChange={(event: any) => {
                            handleCriteriaChange(event, failureCriteria.id);
                          }}
                          label="Aggregate function"
                          name="aggregateFunction"
                        >
                          {metricsObject &&
                            metricsObject[
                              failureCriteria.main.metric
                            ]?.aggregateFunction?.map(
                              (aggregateFunction: string) => (
                                <MenuItem
                                  value={aggregateFunction}
                                  key={aggregateFunction}
                                >
                                  {aggregateFunction}
                                </MenuItem>
                              ),
                            )}
                        </Select>
                        <FormHelperText>
                          {failureCriteria.errors.aggregateFunction}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        required
                        disabled={!failureCriteria.main.metric}
                        {...(failureCriteria.errors.condition && {
                          error: true,
                          helperText: failureCriteria.errors.condition,
                        })}
                      >
                        <InputLabel htmlFor="outlined-native-simple">
                          Condition
                        </InputLabel>
                        <Select
                          value={failureCriteria.main.condition}
                          onChange={(event: any) => {
                            handleCriteriaChange(event, failureCriteria.id);
                          }}
                          label="Condition"
                          name="condition"
                        >
                          {metricsObject &&
                            metricsObject[
                              failureCriteria.main.metric
                            ]?.condition?.map((condition: string) => (
                              <MenuItem value={condition} key={condition}>
                                {condition}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                          {failureCriteria.errors.condition}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                      <TextField
                        required
                        label="Threshold"
                        variant="outlined"
                        disabled={!failureCriteria.main.metric}
                        value={failureCriteria.main.threshold}
                        name="threshold"
                        onChange={(event: any) => {
                          handleCriteriaChange(event, failureCriteria.id);
                        }}
                        fullWidth
                        {...(failureCriteria.errors.threshold && {
                          error: true,
                          helperText: failureCriteria.errors.threshold,
                        })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {
                                metricsObject[failureCriteria.main.metric]
                                  ?.unitofMeasure
                              }
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                      <TextField
                        required={failureCriteria.main.metric !== 'error'}
                        label="Request name"
                        value={failureCriteria.main.requestName}
                        name="requestName"
                        onChange={(event: any) => {
                          handleCriteriaChange(event, failureCriteria.id);
                        }}
                        variant="outlined"
                        disabled={
                          !failureCriteria.main.metric ||
                          failureCriteria.main.metric === 'error'
                        }
                        fullWidth
                        {...(failureCriteria.errors.requestName && {
                          error: true,
                          helperText: failureCriteria.errors.requestName,
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  sm="auto"
                  md={1}
                  lg={1}
                  className={`${classes.flexCenter}`}
                >
                  <IconButton
                    className={classes.padding1}
                    onClick={() => handleDeleteDependency(failureCriteria.id)}
                  >
                    <DeleteOutlineOutlined fontSize="medium" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })
        )}
      </CardContent>
      <CardActions className={`${classes.padding2} ${classes.addConfigFooter}`}>
        <Button variant="outlined" onClick={() => onPrevious()}>
          Previous
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </CardActions>
    </Card>
  );
};

export default FailureCriteria;
