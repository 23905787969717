import { Progress } from '@backstage/core-components';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import React, { useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import type { Entry } from '../utils/types';
import Radar from './Radar';

const useTechRadarLoader = (id: string | undefined) => {
  const errorApi = useApi(errorApiRef);
  const techRadarApi = useApi(techRadarApiRef);

  const { error, value, loading } = useAsync(
    async () => techRadarApi.load(id),
    [techRadarApi, id],
  );

  useEffect(() => {
    if (error) {
      errorApi.post(error);
    }
  }, [error, errorApi]);

  return { loading, value, error };
};

function matchFilter(filter?: string): (entry: any) => boolean {
  const terms = filter
    ?.toLocaleLowerCase('en-US')
    .split(/\s/)
    .map(e => e.trim())
    .filter(Boolean);

  if (!terms?.length) {
    return () => true;
  }

  return entry => {
    const text = `${entry.title} ${entry?.description || ''}`.toLocaleLowerCase(
      'en-US',
    );
    return terms.every(term => text.includes(term));
  };
}

/**
 * Properties of {@link TechRadarComponent}
 *
 * @public
 */
export interface TechRadarComponentProps {
  id?: string;
  width: number;
  height: number;
  svgProps?: object;
  searchText?: string;
}

export function RadarComponent(props: TechRadarComponentProps) {
  const { loading, error, value: data } = useTechRadarLoader(props.id);

  console.log(data);

  const mapToEntries = (loaderResponse: any): Array<Entry> => {
    return loaderResponse.entries
      .filter(matchFilter(props.searchText))
      .map(entry => ({
        id: entry.name,
        quadrant: entry.quadrant,
        title: entry.title,
        ring: entry.ring,
        description: entry.description,
        url: entry.url,
        links: entry.links,
      }));
  };

  return (
    <>
      {loading && <Progress />}
      {!loading && !error && data && (
        <Radar
          {...props}
          rings={data.rings}
          quadrants={data.quadrants}
          entries={mapToEntries(data)}
        />
      )}
    </>
  );
}
