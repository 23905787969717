import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../../../styles/popupStyles';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  popupContent: {
    message: string;
    title: string;
    confirmation: boolean;
  };
}

const ConfigPopup = ({ open, onClose, popupContent }: PopupProps) => {
  const classes = useStyles();
  return (
    <Dialog open={open} fullWidth classes={{ paper: classes.infoPopup }}>
      <div className={classes.popupTitle}>
        <DialogTitle>
          <span>{popupContent?.title}</span>
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers>
        <div className={`${classes.sectionContainer} ${classes.infoMessage}`}>
          {popupContent?.message}: <br></br>
          <br></br>
          Refer to{' '}
          <a href="https://devx.cbre.com/help/ptaas-platform/">
            <u>https://devx.cbre.com/help/ptaas-overview/</u>
          </a>
        </div>
        {!popupContent?.confirmation && (
          <div className={classes.helpInfo}>
            <HelpOutlineOutlinedIcon fontSize="small" />
            <div>
              For further query connect to DevX Team on this email Id:<br></br>
              <a href="mailto:!global-devx-avengers@cbre.com?subject=Query regarding Performance test as a service">
                <u>!GLOBAL-DevX-Avengers</u>
              </a>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ConfigPopup;
