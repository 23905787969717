import React from 'react';
import type { EntrySnapshot } from '../../utils/types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AdjustIcon from '@material-ui/icons/Adjust';

import { MovedState } from '../../utils/types';

export type Props = {
  timeline?: EntrySnapshot[];
};

const RadarTimeline = (props: Props): JSX.Element => {
  const { timeline } = props;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        History
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Moved in direction</TableCell>
              <TableCell align="left">Moved to ring</TableCell>
              <TableCell align="left">Moved on date</TableCell>
              <TableCell align="left">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeline?.length === 0 && (
              <TableRow key="no-timeline">
                <TableCell component="th" scope="row">
                  No Timeline
                </TableCell>
              </TableRow>
            )}
            {timeline?.map(timeEntry => (
              <TableRow key={timeEntry.description}>
                <TableCell component="th" scope="row">
                  {timeEntry.moved === MovedState.Up ? <ArrowUpwardIcon /> : ''}
                  {timeEntry.moved === MovedState.Down ? (
                    <ArrowDownwardIcon />
                  ) : (
                    ''
                  )}
                  {timeEntry.moved === MovedState.NoChange ? (
                    <AdjustIcon />
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                  {timeEntry.ring.name ? timeEntry.ring.name : ''}
                </TableCell>
                <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                  {timeEntry.date.toLocaleDateString()
                    ? timeEntry.date.toLocaleDateString()
                    : ''}
                </TableCell>
                <TableCell align="left">
                  {timeEntry.description ? timeEntry.description : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export { RadarTimeline };