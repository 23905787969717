import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /* Common styles */
    labelAndValue: {
      display: 'flex',
      color: theme.palette.text.secondary,
      margin: `${theme.spacing(0.625)}px 0px`,
      gap: `${theme.spacing(2)}px`,
    },
    promptDetailBox: {
      maxHeight: `${theme.spacing(75.5)}px`,
      borderRadius: `${theme.spacing(2)}px`,
    },
    popupTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.text.secondary,
    },
    sectionContainer: {
      background: theme.palette.background.tertiary,
      padding: `${theme.spacing(1.5)}px`,
      marginBottom: `${theme.spacing(2)}px`,
      borderRadius: `${theme.spacing(0.5)}px`,
    },
    sectionHeading: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      marginBottom: `${theme.spacing(1)}px`,
      lineHeight: `${theme.spacing(2.5)}px`,
    },
    label: {
      minWidth: `${theme.spacing(19.625)}px`,
      color: theme.palette.text.tertiary,
    },

    /* Error info popup styles */
    helpInfo: {
      padding: `0px ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px 0px`,
      display: 'flex',
      gap: `${theme.spacing(1)}px`,
    },
    infoPopup: {
      color: theme.palette.text.secondary,
      '& a': {
        color: theme.palette.alert.link,
      },
    },
    infoMessage: {
      fontSize: `${theme.spacing(2)}px`,
    },
  }),
);
