// import { createApiRef } from '@backstage/core-plugin-api';
import {
  TechRadarApi,
  // TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';
// import {
//   TECH_RADAR_LOCAL_STORAGE_KEY,
//   TECH_RADAR_TTL,
//   getLocalStorageWithTTL,
//   setLocalStorageWithTTL,
// } from '../components/techRadar/utils/localStorage';

export class TechRadarCustomApiImpl implements TechRadarApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async load() {
    const instance = await this.axiosInstancePromise;
    try {
      let response;
      response = await instance
        .get('service/discovery/techradar/data')
        .then(res => res?.data);
      if (response) {
        const filteredResponse = response
          .filter((tool: any) => tool.stage !== '')
          .map((tool: any, idx: string) => ({
            ...tool,
            id: idx,
            quadrant: tool.layer,
            title: tool.name,
            ring: tool.stage,
          }));
        const quadrants = [
          ...new Set(filteredResponse.map((tool: any) => tool.layer)),
        ];
        const rings = [
          ...new Set(filteredResponse.map((tool: any) => tool.stage)),
        ];
        const mappedTools = quadrants.map((quadrant: any) => {
          return filteredResponse.filter(
            (tool: any) => tool.layer === quadrant,
          );
        });

        const data = {
          quadrants: quadrants,
          mappedTools: mappedTools,
          rings: rings.reverse(),
          entries: filteredResponse,
        };
        return data;
      }
    } catch (error) {
      console.error('Error loading tech radar data', error);
      return;
    }
  }
}
