export const RECENTLY_PUBLISHED = {
  RECENTLY_PUBLISHED_CODE: 'devxrecentlypublishedcode',
  RECENTLY_PUBLISHED_ALL: 'devxrecentlypublishedall',
  RECENTLY_PUBLISHED_API: 'devxrecentlypublishedapi',
  RECENTLY_PUBLISHED_IAC: 'devxrecentlypublishediac',
  RECENTLY_PUBLISHED_AIML: 'devxrecentlypublishedai',
  RECENTLY_PUBLISHED_SALESFORCE: 'devxrecentlypublishedsalesforce',
  RECENTLY_PUBLISHED_EXTENSION: 'devxrecentlypublishedextn',
};

export const HIGHEST_RATED = {
  HIGHEST_RATED_CODE: 'devxhighestratedcode',
  HIGHEST_RATED_ALL: 'devxhighestratedall',
  HIGHEST_RATED_API: 'devxhighestratedapi',
  HIGHEST_RATED_IAC: 'devxhighestratediac',
  HIGHEST_RATED_SALESFORCE: 'devxhighestratedsalesforce',
};

export const TOP_CONTRIBUTORS = 'devxtopcontributors';
export const authorization = 'abcd';
export const apiVersion = '1.0';
export const editApiVersion = '1.0';
export const DISCOVER_API_URL = '/api/discovery/data?dashboardName=';
export const REVIEWER_COMMENTS_HEADING = 'Reviewer Comments';
export const PUBLISHER_COMMENTS_HEADING = 'My Comment';
export const REVIEW_TITLE = 'Review';
export const COMMENTS_TITLE = 'Comments';
export const STATUS_APPROVED = 'approved';
export const STATUS_UNDER_REVIEW = 'under review';

export const USER_INFO_API_URL = '/api/service/discovery/userinfo?user=';
export const MAX_CHAR_COUNT_DESC = 50;
// export const GET_ADMIN_DATA="api/service/discovery/admin";
// export const ADD_ADMIN_DATA="api/service/discovery/admin";
// export const GET_REVIEWER_DATA="api/service/discovery/reviewer";
// export const ADD_REVIEWER_DATA="api/service/discovery/reviewer";
// export const DELETE_ADMIN="api/service/discovery/admin?admin=";
// export const DELETE_REVIEWER="api/service/discovery/reviewer";
// export const UPDATE_REVIEWER="api/service/discovery/review";
// export const GET_USER_LIST_FOR_ROLE="api/ms-graph/user?q=";
export const GET_RECENTLY_PUBLISHED_DOCS =
  '/api/discovery/data?dashboardName=devxrecentlypublisheddocs';
export const GET_RECENTLY_PUBLISHED_DOCS_URL =
  '/api/service/discovery/document?documentName=';
// export const GET_SUBSCRIPTIONS='/api/service/discovery/subscription';
// export const GET_SUBSCRIPTION_DASHBOARD='/api/service/discovery/subscriptionDashboard';

export const COMPONENT_TYPE = [
  'package',
  'utility',
  'framework',
  'iac',
  'salesforce',
  'ai',
  'extension'
];
export const customFilterOptions = {
  kind: ['API', 'Component', 'Group', 'User'],
  lifecycle: ['experimental', 'deprecated', 'production'],
};
export const locationType = 'Location';
export const iacType = 'IaC';
export const salesforceType = 'Salesforce';
export const documentationType = 'DOCUMENTATION';
export const deprecatedLifecycle = 'DEPRECATED';
export const experimentalLifecycle = 'EXPERIMENTAL';
export const productionLifecycle = 'PRODUCTION';
export const packageType = 'PACKAGE';
export const utilityType = 'UTILITY';
export const frameworkType = 'FRAMEWORK';
export const aiType = 'AI/ML';
export const extensionType = 'EXTENSION';
export const COMPONENT = 'component';
export const API = 'api';
export const NOT_APPROVED = 'Not Approved';
export const IAC_COMPONENT_TYPE = 'iac';
export const SALESFORCE_COMPONENT_TYPE = 'salesforce';
export const PACKAGE_COMPONENT_TYPE = 'package';
export const AI_COMPONENT_TYPE = 'ai';
export const EXTENSION_COMPONENT_TYPE = 'extension';
export const UNDER_REVIEW = 'Under Review';
export const CHECK_USER_ROLE = 'y';
export const IsBreadCrumbsDisabled = true;
export const API_STATUS_FETCHING = 'FETCHING';
export const API_STATUS_SUCCESS = 'SUCCESS';
export const API_STATUS_ERROR = 'ERROR';
export const REVIEWER_tAB = 'ReviewerTab';
export const EXCLUSION_TAB = 'ExclusionList';
export const ADMIN_TAB = 'AdminTab';
export const ADD_ADMIN =
  'Add Admin (5 users at a time, only display name allowed)';
export const ADD_REVIEWER =
  'Add Reviewer (5 users at a time, only display name allowed)';
export const ADD_EXCLUSION = '5 users at a time, only display name allowed.';
export const SUCCESS_MESSAGE_FOR_ADMIN = 'User is successfully added';
export const ERROR_MESSAGE =
  'You cannot add existing user, please validate and resubmit.';
export const ERROR_MESSAGE_FOR_USER_LIST = 'Not able to fetch user list';
export const STARRED = 'starred';
export const BOOKMARK = 'Bookmark';
export const IMAGE_MAX_SIZE = 15000000;
export const POST_MAX_SIZE = 5242880;
export const TRUE = 'true';
export const FALSE = 'false';

export const DELETE_CONFIRMATION_MESSAGE =
  'Are you sure you want to delete this user?';
export const DELETE_CONFIRMATION_TITLE = 'Confirmation';
export const Storybook_Url = {
  internal: 'https://dev.storybook.emerald.cbre.com',
  dev: 'https://dev.storybook.emerald.cbre.com',
  uat: 'https://test.storybook.emerald.cbre.com',
  prod: 'https://docs.emerald.cbre.com/',
};

export const IS_GAMIFICATION_ENABLE = true;
export const IS_SALESFORCE_ENABLE = true;
export const IS_COMPLEXITY_ENABLE = false;
export const isTopContributedLink = true;
export const TEXT_YES = 'YES';
export const TEXT_NO = 'NO';
export const IS_TOP_MENU_ENABLE = false;
export const TOOL_TIPS_TEXT_FOR_TECH_DOCS =
  'Create a Docs folder under your repo in GitHub” Include mkdocs.yaml file in the root of the repo folder for the Tech Doc to be rendered in DevX';
export const SUBSCRIBES_SUCCESS_MESSAGE =
  'Subscribe email notification has been sent successfully.';
export const SUBSCRIBES_ERROR_MESSAGE =
  'Something went wrong. Please try again after some time.';
export const RUM_CONFIG = {
  // googleAnalyticsTrackingId:${GOOGLE_ANALYTICS_TRACKING_ID},
  applicationId: '354a929b-b420-4368-ba49-a65df9410111',
  clientToken: 'pubb6461447f596564fe19b8ec84869cf19',
};

export const dateDisplay = {
  month_names_short: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  first_short: 'st',
  second_short: 'nd',
  third_short: 'rd',
  th: 'th',
};

export const Colors = {
  ERROR_MAIN: 'FF543E',
  SUCCESS_GREEN: '#50AF78',
  INFO_DARK: '#262698',
  WARNING_YELLOW: '#FF9742',
};

export const CANCEL_AI_RESPONSE_TITLE = 'Please let us know the reason';
export const CANCEL_AI_RESPONSE_OPTIONS = [
  {
    title: 'Irrelevant answer',
    value: 'irrelevant answer',
  },
  {
    title: 'Incorrect answer',
    value: 'incorrect answer',
  },
  {
    title: 'Other',
    value: 'other',
  },
];
export const CANCEL_AI_STATUS = 'Canceled';
export const IS_ADD_Reviewer_IN_ASSETS = true;
export const REPHRASE_AI_TITLE = 'Please rephrase the question';

export const MAX_TITLE = 100;
export const MAX_DESCRIPION = 350;

export const REPORT_STATUS = {
  PROVISIONING: 'provisioning',
  STARTED: 'started',
  DONE: 'done',
  STOPPED: 'stopped',
  FAILED: 'failed',
  ENDED: 'ended'
}
