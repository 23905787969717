import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface Props {
  // Define your component's props here
  legends: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  legend: {
    position: 'relative',
    left: '36%',
  },
  legendContainer: {
    display: 'flex',
    gap: `${theme.spacing(1.5)}px`,
    listStyle: 'none',
    padding: '0px',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(0.5)}px`,
  },
  smallBox: {
    width: `${theme.spacing(1.5)}px`,
    height: `${theme.spacing(1.5)}px`,
    borderRadius: `${theme.spacing(0.5)}px`,
  },
  seperator: {
    marginLeft: `${theme.spacing(1)}px`,
    color: `${theme.palette.primary.main}15`,
  },
}));

const Legend = ({ legends }: Props) => {
  // Implement your component's logic here
  const classes = useStyles();

  return (
    <ul className={classes.legendContainer}>
      {legends.map((legend: any, index: number) => {
        return (
          <li className={classes.legendItem}>
            <span
              className={classes.smallBox}
              style={{ background: legend.bg }}
            ></span>
            <span>{legend.name}</span>
            {index !== legends.length - 1 && (
              <span className={classes.seperator}>|</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Legend;
