import React, { useEffect, useState } from 'react';
import { useStyles } from '../../../../../styles/popupStyles';
import { Typography } from '@material-ui/core';
import { IFailureCriteria } from '../../../../../interfaces';

interface FailureCriteriaItem {
  label: string;
  value: any[];
}

interface IFailureCriteriaProps {
  failureCriteria: IFailureCriteria[];
}

const FailureCriteriaSection = ({ failureCriteria }: IFailureCriteriaProps) => {
  const classes = useStyles();
  const [detailsArr, setDetailsArr] = useState<FailureCriteriaItem[]>([]);

  useEffect(() => {
    loadDetailsArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failureCriteria]);

  const loadDetailsArray = () => {
    setDetailsArr([
      {
        label: 'Metric:',
        value: failureCriteria.map(
          (failureCriteria: any) => failureCriteria.metric,
        ),
      },
      {
        label: 'Aggregate Function:',
        value: failureCriteria.map(
          (failureCriteria: any) => failureCriteria.aggregateFunction,
        ),
      },
      {
        label: 'Condition:',
        value: failureCriteria.map(
          (failureCriteria: any) => failureCriteria.condition,
        ),
      },
      {
        label: 'Threshold:',
        value: failureCriteria.map(
          (failureCriteria: any) => failureCriteria.threshold,
        ),
      },
      {
        label: 'Request name:',
        value: failureCriteria.map(
          (FailureCriteria: any) => FailureCriteria?.requestName,
        ),
      },
    ]);
  };

  return (
    <div className={classes.sectionContainer}>
      <Typography className={classes.sectionHeading}>
        Failure criteria
      </Typography>
      <div>
        {detailsArr.map((item, index) => (
          <div className={classes.labelAndValue}>
            <div className={classes.label} key={index}>
              {item.label}
            </div>
            <div key={index}>{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FailureCriteriaSection;
