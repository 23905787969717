import {
  Content,
  Header,
  Page,
  useSidebarPinState,
} from '@backstage/core-components';
import { Grid, InputBase, makeStyles, Paper, Theme } from '@material-ui/core';
import React from 'react';
import { RadarComponent, type TechRadarComponentProps } from './RadarComponent';
import Legend from './Legend';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) => ({
  overflowXScroll: {
    overflowX: 'scroll',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  legend: {
    position: 'relative',
    left: `calc(50% - 192px)`,
  },
  searchBar: {
    height: '40px',
    padding: `${theme.spacing(0.5)}px`,
  },
}));

export interface TechRadarPageProps extends TechRadarComponentProps {
  title?: string;
  subtitle?: string;
  pageTitle?: string;
}

export function RadarPage(props: TechRadarPageProps) {
  const {
    title = 'Tech Radar',
    subtitle = 'Pick the recommended technologies for your projects',
    pageTitle = 'Company Radar',
    ...componentProps
  } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');

  const legends = [
    {
      name: 'Approved',
      bg: '#50AF78',
    },
    {
      name: 'Restricted',
      bg: '#FF9742',
    },
    {
      name: 'Prohibited',
      bg: '#7774d8',
    },
    {
      name: 'Trial',
      bg: '#FF543E',
    },
  ];

  const { isPinned } = useSidebarPinState();
  const radarContentWidth = isPinned
    ? window.innerWidth - 250
    : window.innerWidth - 10;
  return (
    <Page themeId="tool">
      <Header title={title} subtitle={subtitle} />
      <Content className={classes.overflowXScroll}>
        {/* <ContentHeader title={pageTitle}> */}
        <Grid container>
          <Grid item xs={12} className={classes.spaceBetween}>
            <div className={classes.legend}>
              <Legend legends={legends} />
            </div>

            <Paper component="form" variant="outlined">
              <InputBase
                className={classes.searchBar}
                placeholder="Search"
                inputProps={{ 'aria-label': 'Search' }}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={ev => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                  }
                }}
                endAdornment={<SearchIcon />}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3} direction="row">
          <Grid item xs={12} sm={12} md={12}>
            <RadarComponent
              searchText={searchText}
              {...componentProps}
              width={radarContentWidth}
            />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
}
