import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Button,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import BackButton from '../../../../utils/backButton';
import { useStyles } from '../../../styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExpandIcon from '../../../icons/ExpandIcon';
import { useStatus } from './hooks/useStatus';
import { useParams } from 'react-router';
import { displayTime } from '../../../../utils/dateUtils';
import { HelpComponent } from '../../../../utils/helpComponent';
import HELP_URL from '../../../../utils/helpLinkConstant';

const ReportPage = () => {
  const classes = useStyles();
  const { immutableId, projectId } = useParams();
  const [detailExpanded, setDetailExpanded] = useState(false);
  const [criteriaExpanded, setCriteriaExpanded] = useState(false);
  const [dashboardExpanded, setDashboardExpanded] = useState(false);
  const [status, isLoading, refreshStatus] = useStatus(immutableId!);
  const [iframeKey, setIframeKey] = useState(0);
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const displayStatusTime = (time: string) => {
    const date = new Date(time);
    return `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} `;
  };
  const parseFailCriteria = (criteria: any) => {
    if (!criteria) return [];
    const keys = Object.keys(criteria);
    return keys.map((key: string) => {
      return {
        id: key,
        aggregateFunction: criteria[key].aggregate,
        condition: criteria[key].condition,
        threshold: criteria[key].value,
        requestName: criteria[key].requestName,
        actualValue: criteria[key].actualValue,
        result: criteria[key].result,
        action: criteria[key].action,
        clientMetric: criteria[key].clientMetric,
      };
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL} />
        </Grid>
      </Grid>
      <Grid container className={classes.pagePadding}>
        <Grid item xs={12} className={`${classes.flexSpacebetween}`}>
          <div>
            <Typography>Application: {' ' + projectId}</Typography>
            <Typography>Config ID: {' ' + status.configId}</Typography>
            <Typography>Test run ID: {' ' + immutableId}</Typography>
            <Typography>
              Initiated on: {displayTime(status.createdDateTime || '')}
            </Typography>
          </div>
          <div className={classes.flexCenter}>
            <Button
              variant="contained"
              color="primary"
              className={` ${classes.reportPageButton}`}
              onClick={() => {
                refreshStatus();
              }}
            >
              <GetAppIcon />
              {!isMobile ? <span>Get Status</span> : null}
            </Button>
            <Button color="primary" variant="contained">
              <GetAppIcon />
              {!isMobile ? <span>Download</span> : null}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.reportPaper}>
          <Paper elevation={1} square={false}>
            <Accordion
              expanded={detailExpanded}
              onChange={(event: React.SyntheticEvent, expanded: boolean) => {
                setDetailExpanded(expanded);
              }}
            >
              <AccordionSummary
                aria-controls="test-run-detail"
                id="test-run-detail"
              >
                <div className={classes.flex}>
                  {detailExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  <Typography>Test run details</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {isLoading ? (
                  <LinearProgress />
                ) : (
                  <Grid
                    className={`${classes.flexSpacebetween} ${classes.width100Percent} ${classes.reportBoxContainer}`}
                  >
                    <div className={classes.reportBox}>
                      <Typography>Start time</Typography>
                      <Typography>
                        {displayStatusTime(status.createdDateTime || '')}
                      </Typography>
                    </div>
                    <div className={classes.reportBox}>
                      <Typography>End time</Typography>
                      <Typography>
                        {displayStatusTime(status.endDateTime || '')}
                      </Typography>
                    </div>
                    <div className={classes.reportBox}>
                      <Typography>Test result</Typography>
                      <Typography>{status.testResult}</Typography>
                    </div>
                    <div className={classes.reportBox}>
                      <Typography>Status</Typography>
                      <Typography>{status.status}</Typography>
                    </div>
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.reportPaper}>
          <Paper elevation={1} square={false}>
            <Accordion
              expanded={criteriaExpanded}
              onChange={(event: React.SyntheticEvent, expanded: boolean) => {
                setCriteriaExpanded(expanded);
              }}
            >
              <AccordionSummary
                aria-controls="test-criteria"
                id="test-criteria"
              >
                <div className={classes.flex}>
                  {criteriaExpanded ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                  <Typography>Test criteria</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {isLoading ? (
                  <LinearProgress />
                ) : (
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="test-criteria">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Metric</TableCell>
                          <TableCell align="left">Aggregate function</TableCell>
                          <TableCell align="left">Condition</TableCell>
                          <TableCell align="left">Threshold</TableCell>
                          <TableCell align="left">Request name</TableCell>
                          <TableCell align="left">Actual value</TableCell>
                          <TableCell align="left">Result</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {status &&
                          status.passFailCriteria?.passFailMetrics &&
                          parseFailCriteria(
                            status.passFailCriteria.passFailMetrics,
                          ).map((criteria: any) => {
                            return (
                              <TableRow key={criteria.id}>
                                <TableCell align="left">
                                  {criteria.clientMetric}
                                </TableCell>
                                <TableCell align="left">
                                  {criteria.aggregateFunction}
                                </TableCell>
                                <TableCell align="left">
                                  {criteria.condition}
                                </TableCell>
                                <TableCell align="left">
                                  {criteria.threshold}
                                </TableCell>
                                <TableCell align="left">
                                  {criteria.requestName}
                                </TableCell>
                                <TableCell align="left">
                                  {criteria.actualValue}
                                </TableCell>
                                <TableCell align="left">
                                  {criteria.result}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.reportPaper}>
          <Paper elevation={1} square={false}>
            <Accordion
              expanded={dashboardExpanded}
              onChange={(event: React.SyntheticEvent, expanded: boolean) => {
                setDashboardExpanded(expanded);
              }}
            >
              <AccordionSummary
                aria-controls="performance-test-dashboard"
                id="performance-test-dashboard"
              >
                <div
                  className={`${classes.flexSpacebetween} ${classes.width100Percent}`}
                >
                  <div className={classes.flex}>
                    {dashboardExpanded ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                    <Typography>Performance test dashboard</Typography>
                  </div>
                  <div className={classes.flexCenter}>
                    <Button
                      color="primary"
                      className={` ${classes.reportPageButton}`}
                      variant="outlined"
                      onClick={() => setIframeKey(iframeKey + 1)}
                    >
                      <RefreshIcon />
                      {!isMobile ? <span>Refresh</span> : null}
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      href={status.testDashboardLink}
                      target="_blank"
                    >
                      <ExpandIcon />
                      {!isMobile ? <span>Expand</span> : null}
                    </Button>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <iframe
                  key={iframeKey}
                  src={status.testDashboardLink}
                  className={classes.dashboardIframe}
                />
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
export default ReportPage;
