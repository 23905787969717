const CONSTANTS = {
  errors: {
    devxConfigName: 'Config Name is required',
    apmName: 'APM Name is required',
    apmNumber: 'APM Number is required',
    flowId: 'Flow ID is required',
    /* For Test plan section */
    repoBranchCloneUrl: 'Repo branch url is required',
    testPlan: 'Test Plan is required',
  },
};
export default CONSTANTS;
