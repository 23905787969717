import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useStyles } from '../../../../styles';
import { IApmDetails } from '../../../../interfaces';
import CONSTANTS from '../contants';

const ProjectInfo = ({
  isVisible,
  apmInfo,
  onNext,
  initialState,
}: {
  isVisible: boolean;
  apmInfo: IApmDetails | null;
  onNext: Function;
  initialState: any;
}) => {
  const classes = useStyles();

  const [formObject, setFormObject] = useState({
    devxConfigName: '',
    apmName: apmInfo?.Name,
    apmNumber: apmInfo?.ApmNumber,
    flowId: '',
  });

  useEffect(() => {
    if (initialState)
      setFormObject({
        ...formObject,
        devxConfigName: initialState.devxConfigName,
        flowId: `${initialState.flowId}` || '',
      });
  }, [initialState]);

  const [errors, setErrors] = useState({
    devxConfigName: '',
    apmName: '',
    apmNumber: '',
    flowId: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormObject((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setErrors((prevState: any) => {
      return {
        ...prevState,
        [name]: value ? '' : CONSTANTS.errors[name as keyof Object],
      };
    });
  };

  const validate = () => {
    const errorObj: any = {};
    Object.keys(errors).forEach(key => {
      errorObj[key as keyof Object] = formObject[key as keyof Object]
        ? ''
        : CONSTANTS.errors[key as keyof Object];
    });
    setErrors(errorObj);
    return Object.values(errorObj).every((x: any) => x === '');
  };

  const handleNext = () => {
    const { apmName, ...rest }: any = formObject;
    if (validate()) onNext(rest);
  };

  return (
    <Card
      className={`${classes.heigt100Percent} ${
        isVisible ? '' : classes.displayNone
      } `}
    >
      <CardHeader title="Project Info" />
      <CardContent>
        <Grid container spacing={4} className={classes.paddingBottom2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              required
              label="Config name"
              variant="outlined"
              fullWidth
              value={formObject.devxConfigName}
              name="devxConfigName"
              onChange={handleChange}
              {...(errors.devxConfigName && {
                error: true,
                helperText: errors.devxConfigName,
              })}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              required
              label="APM name"
              variant="outlined"
              fullWidth
              disabled
              value={formObject.apmName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              required
              label="APM number"
              variant="outlined"
              fullWidth
              disabled
              value={formObject.apmNumber}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl
              variant="outlined"
              fullWidth
              required
              {...(errors.flowId && {
                error: true,
                helperText: errors.flowId,
              })}
            >
              <InputLabel htmlFor="outlined-flowId-native-simple">
                Flow id
              </InputLabel>
              <Select
                value={formObject.flowId}
                onChange={(event: any) => {
                  handleChange(event);
                }}
                label="Flow id"
                name="flowId"
              >
                {apmInfo?.flows.map((flow: any) => (
                  <MenuItem value={`${flow.Id}`} key={flow.Id}>
                    {flow.Name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.flowId}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={`${classes.padding2} ${classes.addConfigFooter}`}>
        <Button variant="outlined" disabled>
          Previous
        </Button>
        <Button color="primary" variant="contained" onClick={handleNext}>
          Next
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProjectInfo;
