import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import React, { useState } from 'react';
import { Entry, Ring } from '../../utils/types';
import { RadarLegendLink } from './RadarLegendLink';
import { RadarLegendProps } from './types';
import EntryDetailsPopup from './EntryDetailsPopup';

type RadarLegendRingProps = {
  ring: Ring;
  entries: Entry[];
  classes: ClassNameMap<string>;
  onEntryMouseEnter?: RadarLegendProps['onEntryMouseEnter'];
  onEntryMouseLeave?: RadarLegendProps['onEntryMouseEnter'];
};

export const RadarLegendRing = ({
  ring,
  entries,
  classes,
  onEntryMouseEnter,
  onEntryMouseLeave,
}: RadarLegendRingProps) => {
  const [popupProps, setPopupProps] = useState<any>({
    open: false,
    onClose: () => {},
    onOkClicked: () => {},
    entry: null,
  });

  const handleEntryPopupClose = () => {
    setPopupProps((prevState: any) => {
      return { ...prevState, open: false, entry: null };
    });
  };

  const handleEntryClick = (entry: Entry) => {
    setPopupProps({
      open: true,
      onClose: () => {
        handleEntryPopupClose();
      },
      onOkClicked: () => {
        handleEntryPopupClose();
      },
      entry: entry,
    });
  };
  return (
    <>
      <div data-testid="radar-ring" key={ring.id} className={classes.ring}>
        <h3
          className={classes.ringHeading}
          style={{ color: ring.color }}
          data-testid="radar-legend-heading"
        >
          {ring.name}
        </h3>
        {entries.length === 0 ? (
          <Typography paragraph className={classes.ringEmpty}>
            (empty)
          </Typography>
        ) : (
          <ol className={classes.ringList}>
            {entries.map(entry => (
              <li
                key={entry.id}
                value={(entry.index || 0) + 1}
                onClick={() => handleEntryClick(entry)}
                onMouseEnter={
                  onEntryMouseEnter && (() => onEntryMouseEnter(entry))
                }
                onMouseLeave={
                  onEntryMouseLeave && (() => onEntryMouseLeave(entry))
                }
              >
                <RadarLegendLink
                  classes={classes}
                  entryId={entry.id}
                  url={entry.url}
                  title={entry.title}
                  description={entry.description}
                  active={entry.active}
                  links={entry.links ?? []}
                  timeline={entry.timeline ?? []}
                />
              </li>
            ))}
          </ol>
        )}
      </div>
      {popupProps.open ? <EntryDetailsPopup {...popupProps} /> : null}
    </>
  );
};
