import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Chip,
  useMediaQuery,
  useTheme,
  Theme,
  makeStyles,
  createStyles,
  Tooltip,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withStyles } from '@material-ui/styles';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import MessageIcon from '@material-ui/icons/Message';
import ThumbUpIcon from '../../../feedback/icons/thumbUp';
// @ts-ignore TODO: Keep track if backstage removes it
import { useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import {
  IHomepageQuestionItem,
  InformationExchangeApi,
  informationExchangeApiRef,
} from '../../../../apis/informationExchange';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import LeftArrowIcon from '../icons/leftArrowIcon';
import RightArrowIcon from '../icons/rightArrowIcon';
import useEmblaCarousel from 'embla-carousel-react';
import EmblaCarousel from '../../Carousal/EmblaCarousel';
import Autoplay from 'embla-carousel-autoplay';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tool: {
      flex: 1,
      cursor: 'pointer',
      userSelect: 'none',
      zIndex: 100,
      background: '#FFFFFF',
      borderRadius: '6px',
      minWidth: '236px',
      color: '#003F2D',
      padding: '10px 12px',
    },
    titleBox: {
      height: '36px',
    },
    infoExchangeHeader: {
      display: 'flex',
    },
    infoIconMargin: {
      margin: '5px',
    },
    queTitle: {
      fontSize: '14px',
      // height: '36px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    discussions: {
      height: '16px',
      margin: '13px 0px',
      display: 'flex',
    },
    discussionsSection: {
      display: 'flex',
      color: 'rgba(26, 26, 26, 0.6)',
    },
    queDiscussions: {
      lineHeight: '16px',
      display: 'flex',
      color: '#1A1A1A',
      margin: '2px 0px 0px 2px',
    },
    votes: {
      lineHeight: '16px',
      display: 'flex',
      color: '#1A1A1A',
      margin: '2px 0px 0px 2px',
    },
    queTags: {
      textAlign: 'center',
      height: '24px',
      marginTop: '5px',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    tags: {
      color: '#000000',
      fontSize: '12px',
    },
    recentDiscussions: {
      background: '#032842',
      borderRadius: '8px',
      marginTop: '4px',
      color: '#fff',
      scrollBehavior: 'smooth',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
    },
    recentDiscussionsBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    arrowIcons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '2px',
    },
    arrowIconBtns: {
      marginRight: '5px',
    },
    arrowIconClr: {
      cursor: 'pointer',
      color: '#9aa9b3',
    },
    seeMoreBtn: {
      color: '#17e88f',
      cursor: 'pointer',
    },
    emblaCarousel: {
      height: '140px',
    },
    tooltipLabel: {
      fontSize: '14px',
    },
  }),
);

const StyledBox = withStyles(
  {
    root: {
      display: 'flex',
      color: '#1A1A1A99',
      marginRight: '15px',
    },
  },
  { name: 'BackstageBreadcrumbsStyledBox' },
)(Box);

const QuestionWidget = ({ question }: { question: any }) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const navigate = useNavigate();
  const classes = useStyles();
  const onClickTool = () => {
    navigate(`/information-exchange/question/${question.id}`);
  };
  const tagCharacterLimit = question.tags.length > 2 ? 9 : 15;

  return (
    <Box
      sx={{
        mr: 3,
      }}
      className={classes.tool}
      onClick={onClickTool}
    >
      <Box className={classes.titleBox}>
        <Typography className={classes.queTitle}>{question.title}</Typography>
      </Box>
      <Box className={classes.discussions}>
        <div className={classes.discussionsSection}>
          <StyledBox>
            <MessageIcon fill="#1A1A1A" />
            <Typography className={classes.queDiscussions}>
              {question.discussions ? question.discussions : 0}
            </Typography>
          </StyledBox>
          <StyledBox>
            <ThumbUpIcon
              fill="rgba(26, 26, 26, 0.6)"
              style={{ marginTop: '-2px' }}
            />
            <Typography className={classes.votes}>
              {question.votes ? question.votes : 0}
            </Typography>
          </StyledBox>
        </div>
      </Box>
      <Box className={classes.queTags} draggable={false}>
        {question.tags.length > 0 &&
          question.tags
            .slice(0, 2)
            .map((tag: any, idx: number) => (
              <Chip
                key={question.title + idx}
                label={
                  tag.length > tagCharacterLimit ? tag.slice(0, 7) + '..' : tag
                }
                size="small"
              />
            ))}
        {question.tags.length > 2 && (
          <div>
            <Typography className={classes.tags}>
              + {question.tags.length - 2} tags
            </Typography>
          </div>
        )}
      </Box>
    </Box>
  );
};

const InformationExchangeLinksWidget = ({ id }: { id: any }) => {
  const informationExchangeApi: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [questionsToRender, setQuestionsToRender] = useState<any>([]);
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const infoToolTipTitle =
    "Information Exchange is a Q&A forum for CBRE's D&T community. Ask your questions that can't be posted on the internet or solved by the general public, but need CBRE specific knowledge and context. Share your own knowledge too with others by responding as well.";

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      containScroll: 'trimSnaps',
      slidesToScroll: 1,
      dragFree: true,
      align: 'start',
      loop: true,
    },
    [
      Autoplay({
        stopOnInteraction: false,
        // active: false,
        stopOnMouseEnter: true,
        delay: 4000,
      }),
    ],
  );

  const getHomepageDisplayQuestions = () => {
    setLoading(true);
    informationExchangeApi
      .getAllQuestionHomepage({
        limit: 5,
      })
      .then((value: IHomepageQuestionItem[] | undefined) => {
        if (value != undefined && value.length > 0) {
          setQuestionsToRender(value || []);
        } else {
          setQuestionsToRender(value || []);
        }
        setLoading(false);
      })
      .catch(() => {
        setQuestionsToRender([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getHomepageDisplayQuestions();
  }, []);
  const navigate = useNavigate();
  const classes = useStyles();
  const onClickSeeMore = () => {
    navigate('/information-exchange');
  };

  const onClickLeftArrow = () => {
    if (emblaApi && emblaApi?.canScrollPrev()) {
      emblaApi?.scrollPrev();
    }
  };

  const onClickRightArrow = () => {
    if (emblaApi && emblaApi?.canScrollNext()) {
      emblaApi?.scrollNext();
    }
  };

  return (
    <Box
      sx={{
        // @ts-ignore
        pt: 1,
        pl: 2,
        pr: 2,
      }}
      className={classes.recentDiscussions}
      id={id}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className={classes.infoExchangeHeader} xs={8} md={7}>
              <Typography variant="h6">Recent Discussions </Typography>
              <Tooltip
                title={infoToolTipTitle}
                placement="top-start"
                classes={{ tooltip: classes.tooltipLabel }}
              >
                <InfoOutlinedIcon
                  className={classes.infoIconMargin}
                  fontSize="small"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4} md={5}>
              <Box
                sx={{
                  mb: 1,
                  mt: 1,
                }}
                className={classes.recentDiscussionsBox}
              >
                {!isMobile ? (
                  <Box className={classes.arrowIcons}>
                    <div
                      className={classes.arrowIconBtns}
                      onClick={onClickLeftArrow}
                    >
                      <LeftArrowIcon className={classes.arrowIconClr} />
                    </div>
                    <div
                      className={classes.arrowIconBtns}
                      onClick={onClickRightArrow}
                    >
                      <RightArrowIcon className={classes.arrowIconClr} />
                    </div>
                  </Box>
                ) : null}
                <Typography
                  className={classes.seeMoreBtn}
                  onClick={onClickSeeMore}
                >
                  {!isMobile ? 'Post question' : 'Post query'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.emblaCarousel}>
                {loading && <Typography>Loading ...</Typography>}
                {(loading === false && questionsToRender === undefined) ||
                  (questionsToRender?.length === 0 && (
                    <Typography>No discussions available</Typography>
                  ))}
                <Box>
                  {loading === false &&
                    questionsToRender !== undefined &&
                    Array.isArray(questionsToRender) &&
                    questionsToRender.length > 0 && (
                      <EmblaCarousel
                        ref={emblaRef}
                        slides={questionsToRender.map(item => (
                          <QuestionWidget key={item.id} question={item} />
                        ))}
                      ></EmblaCarousel>
                    )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InformationExchangeLinksWidget;
