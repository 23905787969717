import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import ProjectInfoSection from './ProjectInfoSection';
import TestPlanSection from './TestPlanSection';
import DependencyFilesSection from './DependencyFilesSection';
import GlobalTrafficSection from './GlobalTrafficSection';
import FailureCriteriaSection from './FailureCriteriaSection';
import { IConfigData } from '../../../../../interfaces';
import SecretsSection from './SecretsSection';
import { useStyles } from '../../../../../styles/popupStyles';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  popupContent: IConfigData;
}

const ViewConfig = ({ open, onClose, popupContent }: PopupProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.promptDetailBox }}
      fullWidth
      maxWidth="md"
    >
      <div className={classes.popupTitle}>
        <DialogTitle>
          <span>View Config</span>
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            <ProjectInfoSection {...popupContent} />
            <TestPlanSection {...popupContent} />
            {popupContent.config?.configurationFiles && (
              <DependencyFilesSection {...popupContent} />
            )}
            {popupContent.config?.secrets && (
              <SecretsSection secrets={popupContent.config?.secrets} />
            )}
            <GlobalTrafficSection
              globalTraffic={popupContent.config?.globalTraffic || []}
            />
            {popupContent.config?.failureCriteria &&
              popupContent.config.failureCriteria.length > 0 && (
                <FailureCriteriaSection
                  failureCriteria={popupContent.config.failureCriteria}
                />
              )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewConfig;
