import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import StopCircle from '@material-ui/icons/StopCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { DateTime } from 'luxon';
import { useStyles } from '../../../../styles';
import { IPTRun } from '../../../../interfaces';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { capitalizeFirstLetter } from '../../utils';
import * as constants from '../../../../../utils/constant';
const { PROVISIONING, STARTED, DONE, STOPPED, FAILED, ENDED } =
  constants.REPORT_STATUS;

export interface IRunsTableProps {
  runsData: IPTRun[];
  onStopRun: Function;
  onDownloadRunResults: Function;
  sort: 'descending' | 'ascending';
  onSort: Function;
  onViewInfoClicked: Function;
}

const RunsTable = ({
  runsData,
  onStopRun,
  sort,
  onSort,
  onViewInfoClicked,
}: IRunsTableProps) => {
  const classes: any = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = React.useState<number>(0);
  const navigate = useNavigate();

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = () => {
    onSort(sort === 'descending' ? 'ascending' : 'descending');
  };

  const navigateToReportPage = (immutableId: string, apmNumber: string) => {
    navigate(`${immutableId}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" className={classes.table} aria-label="a dense table">
        <TableHead>
          <TableRow classes={{ root: classes.tableHead }}>
            <TableCell className={classes.dateTimeTableCell}>
              <Typography
                classes={{ root: classes.tableHeadCell }}
                variant="subtitle2"
              >
                Date & Time
              </Typography>
              <IconButton
                className={classes.actionButtonRun}
                onClick={() => handleSort()}
              >
                {sort === 'descending' ? (
                  <ArrowDownward fontSize="small" />
                ) : (
                  <ArrowUpward fontSize="small" />
                )}
              </IconButton>
            </TableCell>
            <TableCell>
              <Typography
                classes={{ root: classes.tableHeadCell }}
                variant="subtitle2"
              >
                Config name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                classes={{ root: classes.tableHeadCell }}
                variant="subtitle2"
              >
                Run id
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                classes={{ root: classes.tableHeadCell }}
                variant="subtitle2"
              >
                Run by
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                classes={{ root: classes.tableHeadCell }}
                variant="subtitle2"
              >
                Status
              </Typography>
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {runsData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((config: any) => (
              <TableRow
                key={config.immutableId}
                classes={{ root: classes.configTableRow }}
              >
                <TableCell classes={{ root: classes.tableBodyCell }}>
                  <Typography variant="subtitle2">
                    {DateTime.fromISO(config.requestTime).toFormat(
                      'dd MMM yyyy, hh:mm a',
                    )}
                  </Typography>
                </TableCell>
                <TableCell classes={{ root: classes.tableBodyCell }}>
                  {config.configName}
                </TableCell>
                <TableCell classes={{ root: classes.tableBodyCell }}>
                  {config.immutableId}
                </TableCell>
                <TableCell classes={{ root: classes.tableBodyCell }}>
                  {config.requestUser}
                </TableCell>
                <TableCell classes={{ root: classes.tableBodyCell }}>
                  <div className={classes.configStatus}>
                    {config.status.toLowerCase() !== 'provisioning' && (
                      <FiberManualRecord
                        fontSize="small"
                        classes={{
                          root: classes[
                            `colorRun${config.status.toLowerCase()}`
                          ],
                        }}
                      />
                    )}
                    {config.status.toLowerCase() === 'provisioning' && (
                      <FiberManualRecordOutlinedIcon
                        fontSize="small"
                        classes={{
                          root: classes[
                            `colorRun${config.status.toLowerCase()}`
                          ],
                        }}
                      />
                    )}
                    {capitalizeFirstLetter(config.status)}
                  </div>
                </TableCell>
                <TableCell className={classes.flexSpacebetween}>
                  <IconButton
                    classes={{ root: classes.actionButtonRun }}
                    disabled={config.status.toLowerCase() !== STARTED}
                  >
                    <Tooltip
                      title="Stop run"
                      arrow={true}
                      placement="bottom-end"
                    >
                      <StopCircle
                        onClick={() => onStopRun(config.immutableId)}
                        fontSize="medium"
                      />
                    </Tooltip>
                  </IconButton>
                  {
                    // If status is failed, show the error message
                    config.status.toLowerCase() === FAILED && (
                      <IconButton
                        classes={{ root: classes.actionButtonRun }}
                        onClick={() => {
                          onViewInfoClicked(config?.statusMessage);
                        }}
                      >
                        <ErrorOutlineIcon fontSize="medium" />
                      </IconButton>
                    )
                  }
                  <IconButton
                    classes={{ root: classes.actionButtonRun }}
                    disabled={
                      config.status.toLowerCase() !== ENDED &&
                      config.status.toLowerCase() !== STARTED
                    }
                    onClick={() => {
                      navigateToReportPage(
                        config.immutableId,
                        config.apmNumber,
                      );
                    }}
                  >
                    <Tooltip
                      title="View report"
                      arrow={true}
                      placement="bottom-end"
                    >
                      <AssessmentIcon fontSize="medium" />
                    </Tooltip>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={runsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default RunsTable;
